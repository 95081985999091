<template>
  <b-button
    id="memberModal_open"
    variant="outline-success"
    style="display: none"
    data-bs-toggle="modal"
    data-bs-target="#memberModal"
  >{{ $t('common.button.confirm') }}
  </b-button>
  <!-- Modal for confirmation -->
  <div
    id="memberModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="memberModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="memberModalLabel" class="modal-title"></h5>
          <button
            id="memberModal_close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="member_info" class="text-left">
            <div v-if="member_info.hideName" class="row">
              <div class="col-12">
                <span>姓名 - {{ member_info.hideName }}</span>
              </div>
            </div>
            <div v-if="member_info.hidePhone" class="row">
              <div class="col-12">
                <span>聯絡電話 - {{ member_info.hidePhone }}</span>
              </div>
            </div>
            <div v-if="member_info.gender" class="row">
              <div class="col-12">
                <span>性別 - {{ member_info.gender }}</span>
              </div>
            </div>
            <div v-if="member_info.email" class="row">
              <div class="col-12">
                <span>電郵 - {{ member_info.email }}</span>
              </div>
            </div>
            <div v-if="member_info.birthday" class="row">
              <div class="col-12">
                <span>出生日期 - {{ member_info.birthday }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-6 text-right">
                <b-button variant="success" @click="prefill()">{{
                  $t('common.button.apply')
                }}</b-button>
              </div>
              <div class="col-6 text-left">
                <b-button variant="secondary" data-bs-dismiss="modal" aria-label="Close">{{
                  $t('common.button.back')
                }}</b-button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" style="display: none">
          <b-button variant="outline-success" @click="submit()">{{
            $t('common.button.submit')
          }}</b-button>
        </div>
      </div>
    </div>
  </div>
  <!-- End of Modal for confirmation -->

  <!-- Modal for confirmation-->
  <div
    id="confirmModal"
    class="modal fade"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-labelledby="confirmModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="confirmModalLabel" class="modal-title"></h5>
          <button
            id="confirmModal_close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <span>職員編號</span>
              <CFormInput
                v-model="staff_code"
                size="sm"
                type="password"
                @change="staff_code_check()"
              />
            </div>
          </div>
          <div v-if="staff_code_checked && !vaild_staff_code" class="row">
            <div class="col-12">
              <span class="text-danger">{{
                $t('common.message.staff_code_error')
              }}</span>
            </div>
          </div>
          <div v-if="vaild_staff_code">
            <br /><b-button variant="success" @click="submit()">{{
              $t('common.button.submit')
            }}</b-button>
          </div>
        </div>
        <div class="modal-footer" style="display: none">
          <b-button variant="outline-success" @click="submit()">{{
            $t('common.button.submit')
          }}</b-button>
        </div>
      </div>
    </div>
  </div>

  <!-- Alert Message -->
  <div class="row d-print-none">
    <div class="col-12">
      <b-alert :show="dismissCountDown" dismissible fade variant="success">
        <span>{{ $t('common.message.successful') }}</span>
      </b-alert>
    </div>
    <div class="col-12">
      <b-alert :show="dismissCountDown2" dismissible fade variant="success">
        <span>{{ $t('common.message.tempsave_3stime') }}</span>
      </b-alert>
    </div>
  </div>
  <!-- End of Alert Message -->

  <b-overlay :show="showoverlay" rounded="sm" class="text-left">
    <!--Question-->
    <div v-if="system_ready && all_complete == false">
      <div class="row text-right">
        <div class="col-12">
          <span class="spc_small">(職員專用)</span>
        </div>
      </div>
      <div id="member_no" class="row">
        <div class="col-9 text-right">
          <span>會員編號 Membership No.</span>
        </div>
        <div class="col-3 text-left">
          <CFormInput
            ref="member_no"
            v-model="member_no"
            size="sm"
            type="text"
            @change="member_check()"
          />
        </div>
      </div>
      <div v-if="check_member_msg" class="row text-right">
        <div class="col-12">
          <span class="text-danger">{{ check_member_msg }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-9 text-right">
          <span>日期</span>
        </div>
        <div class="col-3 text-left">
          <datepicker
            v-model="form_date"
            size="sm"
            auto-apply
            input-format="yyyy-MM-dd"
            :clearable="false"
            :enable-time-picker="false"
          />
        </div>
      </div>
      <div id="advisor" class="row">
        <div class="col-9 text-right">
          <span>健康顧問 / Health Advisor</span>
        </div>
        <div class="col-3 text-left">
          <Multiselect
            v-model="advisor"
            mode="single"
            id="advisor"
            ref="advisor"
            :close-on-select="true"
            :searchable="false"
            :create-option="false"
            :options="advisor_list"
            valueProp="id"
            trackBy="nickname"
            label="nickname"
          />
        </div>
      </div>
      <div v-if="check_advisor_msg" class="row text-right">
        <div class="col-12">
          <span class="text-danger">{{ check_advisor_msg }}</span>
        </div>
      </div>
      <div class="card mb-4">
        <div class="card-header pb-0">
          <!--Member Info-->
          <div class="row">
            <div class="col-3">
              <span>姓名</span>
              <b-form-input
                v-model="member_name"
                size="sm"
                placeholder="須與身份證上姓名相同"
              ></b-form-input>
            </div>
            <div class="col-3">
              <span>稱呼</span>
              <b-form-input v-model="nickname" size="sm"></b-form-input>
            </div>
            <div class="col-3">
              <span>性別</span>
              <b-form-radio-group
                v-model="gender"
                :options="options8"
                value-field="item"
                :text-field="name_lang"
                disabled-field="notEnabled"
              ></b-form-radio-group>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <span>ID. No. 身份證號碼</span>
              <b-form-input
                v-model="id_number"
                size="sm"
                placeholder="英文字母及頭3位數字"
                maxlength="4"
              ></b-form-input>
            </div>
            <div class="col-3">
              <span>聯絡電話</span>
              <b-form-input v-model="phone" size="sm"></b-form-input>
            </div>
            <div class="col-3">
              <span>電郵</span>
              <b-form-input v-model="email" size="sm" type="email"></b-form-input>
            </div>
            <div class="col-3">
              <span>出生日期</span>
              <datepicker
                v-model="birthday"
                size="sm"
                auto-apply
                input-format="yyyy-MM-dd"
                :clearable="false"
                :enable-time-picker="false"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              <span class="spc_small"
                >(新客優惠，可享一次，請出示身份證明文件確認，謝謝)</span
              >
            </div>
            <div class="col-3">
              <span class="spc_small">(請填寫，需評估身體狀況)</span>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <span>地址</span>
              <b-form-input v-model="address" size="sm"></b-form-input>
            </div>
            <div class="col-2">
              <span>身高(cm)</span>
              <b-form-input
                v-model="height"
                size="sm"
                onkeydown="return event.keyCode !== 69"
                type="number"
              ></b-form-input>
            </div>
            <div class="col-2">
              <span>體重(KG)</span>
              <b-form-input
                v-model="weight"
                size="sm"
                onkeydown="return event.keyCode !== 69"
                type="number"
              ></b-form-input>
            </div>
            <div class="col-2">
              <span>職業</span>
              <b-form-input v-model="occupation" size="sm"></b-form-input>
            </div>
          </div>
          <br />
          <!--Section 1-->
          <div id="question_section" class="row">
            <div class="col-12">
              請耐心回答以下問題，有助選擇適合之療程！ (請在適當的空格內加上"<i
                class="fas fa-check"
              ></i
              >"號及選擇適用者)
            </div>
          </div>
          <!--Loop question from DB-->
          <div v-for="item in info" :key="item.id">
            <div class="row">
              <div class="col-6">
                {{ getQuestion(item.id).name_tc }} <span v-if="!item.value && item.option.length == 0 && checkvalid == true" class="text-danger">{{ $t('common.message.required') }}</span>
              </div>
              <div v-if="item.input_type == 'radio'" class="col-6">
                <b-form-radio-group
                  v-model="item.value"
                  :options="options"
                  value-field="item"
                  :text-field="name_lang"
                  disabled-field="notEnabled"
                ></b-form-radio-group>
              </div>
            </div>
            <div v-if="item.id == 4 && item.value == 'Y'" class="row">
              <div class="col-6">
                <span>甚麼健康食品？</span>
              </div>
              <div class="col-6">
                <b-form-input v-model="item.value_ext" size="sm"></b-form-input>
              </div>
            </div>
            <div v-if="item.id == 5 && item.value == 'Y'" class="row">
              <div class="col-6"></div>
              <div class="col-6">
                <b-form-radio-group
                  v-model="item.value_ext"
                  :options="options2"
                  value-field="item"
                  :text-field="name_lang"
                  disabled-field="notEnabled"
                ></b-form-radio-group>
              </div>
            </div>
            <template v-if="item.id == 6">
              <div class="row">
                <template v-for="option in item.option" :key="option.id">
                  <div :class="'col-' + option.column_width">
                    <div class="row">
                      <template v-if="option.cus_needed == 1">
                        <template v-if="option.id == 5">
                          <div class="col-4">
                            <b-form-checkbox
                              v-model="option.checkbox_value"
                              value="1"
                              unchecked-value="not_accepted"
                            >
                              {{ getOption(item.id, option.id).name_tc }}
                            </b-form-checkbox>
                          </div>
                          <div class="col-8">
                            <CFormInput
                              v-model="option.ext_1"
                              size="sm"
                              type="text"
                              placeholder="血壓指數"
                              @change="defaulttick(option)"
                            />
                            <b-form-radio-group
                              v-model="option.ext_2"
                              :options="options3"
                              value-field="item"
                              :text-field="name_lang"
                              disabled-field="notEnabled"
                              @change="defaulttick(option)"
                            ></b-form-radio-group>
                          </div>
                        </template>
                        <template v-if="option.id == 6">
                          <div class="col-4">
                            <b-form-checkbox
                              v-model="option.checkbox_value"
                              value="1"
                              unchecked-value="not_accepted"
                            >
                              {{ getOption(item.id, option.id).name_tc }}
                            </b-form-checkbox>
                          </div>
                          <div class="col-8">
                            <b-form-radio-group
                              v-model="option.ext_1"
                              :options="options4"
                              value-field="item"
                              :text-field="name_lang"
                              disabled-field="notEnabled"
                              @change="defaulttick(option)"
                            ></b-form-radio-group>
                          </div>
                        </template>
                        <template v-if="option.id == 7">
                          <div class="col-4">
                            <b-form-checkbox
                              v-model="option.checkbox_value"
                              value="1"
                              unchecked-value="not_accepted"
                            >
                              {{ getOption(item.id, option.id).name_tc }}
                            </b-form-checkbox>
                          </div>
                          <div class="col-8">
                            <b-form-radio-group
                              v-model="option.ext_1"
                              :options="options5"
                              value-field="item"
                              :text-field="name_lang"
                              disabled-field="notEnabled"
                              @change="defaulttick(option)"
                            ></b-form-radio-group>
                            <b-form-input
                              v-if="option.ext_1 == 3"
                              v-model="option.ext_other"
                              size="sm"
                            ></b-form-input>
                          </div>
                        </template>
                        <template v-if="option.id == 8">
                          <div class="col-4">
                            <b-form-checkbox
                              v-model="option.checkbox_value"
                              value="1"
                              unchecked-value="not_accepted"
                            >
                              {{ getOption(item.id, option.id).name_tc }}
                            </b-form-checkbox>
                          </div>
                          <div class="col-8">
                            <b-form-radio-group
                              v-model="option.ext_1"
                              :options="options6"
                              value-field="item"
                              :text-field="name_lang"
                              disabled-field="notEnabled"
                              @change="defaulttick(option)"
                            ></b-form-radio-group>
                            <b-form-input
                              v-if="option.ext_1 == 2"
                              v-model="option.ext_other"
                              size="sm"
                            ></b-form-input>
                          </div>
                        </template>
                        <template v-if="option.id == 9">
                          <div class="col-4">
                            <b-form-checkbox
                              v-model="option.checkbox_value"
                              value="1"
                              unchecked-value="not_accepted"
                            >
                              {{ getOption(item.id, option.id).name_tc }}
                            </b-form-checkbox>
                          </div>
                          <div class="col-4">
                            <CFormInput
                              v-model="option.ext_1"
                              size="sm"
                              type="text"
                              placeholder="年份"
                              @change="defaulttick(option)"
                            />
                          </div>
                        </template>
                        <template v-if="option.id == 10">
                          <div class="col-2">
                            <b-form-checkbox
                              v-model="option.checkbox_value"
                              value="1"
                              unchecked-value="not_accepted"
                            >
                              {{ getOption(item.id, option.id).name_tc }}
                            </b-form-checkbox>
                          </div>
                          <div class="col-2">
                            <CFormInput
                              v-model="option.ext_1"
                              size="sm"
                              type="text"
                              placeholder="患病年份"
                              @change="defaulttick(option)"
                            />
                          </div>
                          <div class="col-2">
                            <CFormInput
                              v-model="option.ext_2"
                              size="sm"
                              type="text"
                              placeholder="何種癌症"
                              @change="defaulttick(option)"
                            />
                          </div>
                          <div class="col-2">
                            <CFormInput
                              v-model="option.ext_3"
                              size="sm"
                              type="text"
                              placeholder="治療方法"
                              @change="defaulttick(option)"
                            />
                          </div>
                          <div class="col-4">
                            <datepicker
                              v-model="option.ext_4"
                              size="sm"
                              auto-apply
                              input-format="yyyy-MM-dd"
                              placeholder="完成治療日期"
                              :enable-time-picker="false"
                            />
                          </div>
                          <div class="col-8"></div>
                          <div class="col-4">
                            <span class="spc_small">*本人已康復，不須再治療</span
                            ><br />
                            <span class="spc_small"
                              >(如仍在癌症治療當中，不可進行療程)</span
                            >
                          </div>
                        </template>
                        <template v-if="option.id == 11">
                          <div class="col-4">
                            <b-form-checkbox
                              v-model="option.checkbox_value"
                              value="1"
                              unchecked-value="not_accepted"
                            >
                              {{ getOption(item.id, option.id).name_tc }}
                            </b-form-checkbox>
                          </div>
                          <div class="col-8">
                            <CFormInput
                              v-model="option.ext_1"
                              size="sm"
                              type="text"
                              placeholder="備註"
                              @change="defaulttick(option)"
                            />
                          </div>
                        </template>
                        <template v-if="option.id == 13">
                          <div class="col-4">
                            <b-form-checkbox
                              v-model="option.checkbox_value"
                              value="1"
                              unchecked-value="not_accepted"
                            >
                              {{ getOption(item.id, option.id).name_tc }}
                            </b-form-checkbox>
                          </div>
                          <div class="col-8">
                            <CFormInput
                              v-model="option.ext_1"
                              size="sm"
                              type="text"
                              placeholder="位置"
                              @change="defaulttick(option)"
                            />
                          </div>
                        </template>
                        <template v-if="option.id == 14">
                          <div class="col-4">
                            <b-form-checkbox
                              v-model="option.checkbox_value"
                              value="1"
                              unchecked-value="not_accepted"
                            >
                              {{ getOption(item.id, option.id).name_tc }}
                            </b-form-checkbox>
                          </div>
                          <div class="col-4">
                            <CFormInput
                              v-model="option.ext_1"
                              size="sm"
                              type="text"
                              placeholder="位置"
                              @change="defaulttick(option)"
                            />
                          </div>
                        </template>
                        <template v-if="option.id == 15">
                          <div class="col-4">
                            <b-form-checkbox
                              v-model="option.checkbox_value"
                              value="1"
                              unchecked-value="not_accepted"
                            >
                              {{ getOption(item.id, option.id).name_tc }}
                            </b-form-checkbox>
                          </div>
                          <div class="col-4">
                            <CFormInput
                              v-model="option.ext_1"
                              size="sm"
                              type="text"
                              placeholder="類型"
                              @change="defaulttick(option)"
                            />
                          </div>
                          <div class="col-4">
                            <CFormInput
                              v-model="option.ext_2"
                              size="sm"
                              type="text"
                              placeholder="位置"
                              @change="defaulttick(option)"
                            />
                          </div>
                        </template>
                        <template v-if="option.id == 17">
                          <div class="col-4">
                            <b-form-checkbox
                              v-model="option.checkbox_value"
                              value="1"
                              unchecked-value="not_accepted"
                            >
                              {{ getOption(item.id, option.id).name_tc }}
                            </b-form-checkbox>
                          </div>
                          <div class="col-8">
                            <CFormInput
                              v-model="option.ext_1"
                              size="sm"
                              type="text"
                              placeholder="備註"
                              @change="defaulttick(option)"
                            />
                          </div>
                        </template>
                        <template v-if="option.id == 19">
                          <div class="col-4">
                            <b-form-checkbox
                              v-model="option.checkbox_value"
                              value="1"
                              unchecked-value="not_accepted"
                            >
                              {{ getOption(item.id, option.id).name_tc }}
                            </b-form-checkbox>
                          </div>
                          <div class="col-4">
                            <CFormInput
                              v-model="option.ext_1"
                              size="sm"
                              type="text"
                              placeholder="懷孕週期"
                              @change="defaulttick(option)"
                            />
                          </div>
                        </template>
                        <template v-if="option.id == 20">
                          <div class="col-4">
                            <b-form-checkbox
                              v-model="option.checkbox_value"
                              value="1"
                              unchecked-value="not_accepted"
                            >
                              {{ getOption(item.id, option.id).name_tc }}
                            </b-form-checkbox>
                          </div>
                          <div class="col-4">
                            <CFormInput
                              v-model="option.ext_1"
                              size="sm"
                              type="text"
                              placeholder="手術類型"
                              @change="defaulttick(option)"
                            />
                          </div>
                          <div class="col-4">
                            <CFormInput
                              v-model="option.ext_2"
                              size="sm"
                              type="text"
                              placeholder="年份"
                              @change="defaulttick(option)"
                            />
                          </div>
                        </template>
                        <template v-if="option.id == 21">
                          <div class="col-4">
                            <b-form-checkbox
                              v-model="option.checkbox_value"
                              value="1"
                              unchecked-value="not_accepted"
                            >
                              {{ getOption(item.id, option.id).name_tc }}
                            </b-form-checkbox>
                          </div>
                          <div class="col-8">
                            <CFormInput
                              v-model="option.ext_1"
                              size="sm"
                              type="text"
                              placeholder="藥物類別"
                              @change="defaulttick(option)"
                            />
                          </div>
                        </template>
                        <template v-if="option.id == 22">
                          <div class="col-4">
                            <b-form-checkbox
                              v-model="option.checkbox_value"
                              value="1"
                              unchecked-value="not_accepted"
                            >
                              {{ getOption(item.id, option.id).name_tc }}
                            </b-form-checkbox>
                          </div>
                          <div class="col-8">
                            <CFormInput
                              v-model="option.ext_1"
                              size="sm"
                              type="text"
                              @change="defaulttick(option)"
                            />
                          </div>
                        </template>
                      </template>
                      <template v-else>
                        <div class="col-12">
                          <b-form-checkbox
                            v-model="option.checkbox_value"
                            value="1"
                            unchecked-value="not_accepted"
                          >
                            {{ getOption(item.id, option.id).name_tc }}
                          </b-form-checkbox>
                        </div>
                      </template>
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </div>
          <br />
          <div class="row">
            <div class="col-1">備註</div>
            <div class="col-11">
              <b-form-input v-model="remark" size="sm"></b-form-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <span class="spc_small"
                >*如有以上某些狀況，可能不建議進行某些療程。</span
              >
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-12">
              <span class="spc_small"
                >Please read and sign the Personal Information Collection Statement.
                請閱讀並簽署個人資料收集聲明。</span
              >
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <span class="spc_small">機能再生ANKH收集個人資料聲明</span>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <span class="spc_small"
                >客戶向 機能再生ANKH 提供的個人資料只會用作履行 機能再生ANKH
                和客戶之間的合約，並可能會根據法律規定交給政府部門及司法當局。</span
              >
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <span class="spc_small"
                >根據法律規定，客戶可向本公司要求查閱及更正其個人資料。</span
              >
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-12">
              <span>請問從何途徑認識我們:</span>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <b-form-radio-group
                v-model="ref"
                :options="options7"
                value-field="item"
                :text-field="name_lang"
                disabled-field="notEnabled"
              ></b-form-radio-group>
            </div>
            <template v-if="ref == 1">
              <div class="col-3">
                <b-form-input
                  v-model="ref_name"
                  size="sm"
                  placeholder="介紹人姓名"
                ></b-form-input>
              </div>
              <div class="col-3">
                <b-form-input
                  v-model="ref_phone"
                  size="sm"
                  placeholder="介紹人電話"
                ></b-form-input>
              </div>
            </template>
            <template v-if="ref == 2">
              <div class="col-3">
                <b-form-input v-model="ref_other" size="sm"></b-form-input>
              </div>
            </template>
          </div>
          <div class="row">
            <div class="col-12">
              <b-form-checkbox
                v-model="agreement_member"
                value="1"
                unchecked-value="not_accepted"
              >
                本人願意日後接收由機能再生ANKH 及其集團發出的會員及生日優惠資訊。
              </b-form-checkbox>
            </div>
          </div>
          <br />
          <!--End of Loop-->
          <!--End of Section 1-->
        </div>
      </div>
      <div v-if="e_sign == true" class="row text-right">
        <div class="col-2"></div>
        <div class="col-8">
          <div class="text-center">
            <span>顧客簽署</span><br />
            <span style="display: none; color: red;" id="e_sign_label">請簽署<br /></span>
            <!--
            <b-button
              size="sm"
              variant="outline-secondary"
              @click="esign_clear()"
              >{{ $t('common.button.re_sign') }}</b-button
            >&nbsp;
            <b-button
              size="sm"
              variant="outline-success"
              @click="esign_lock()"
              >{{ $t('common.button.confirm') }}</b-button
            >
            -->
          </div>
          <div class="card mb-8">
            <span style="display: none; color: red;" id="e_sign_confirm">簽署已確定</span>
            <VueSignaturePad
              id="signature"
              ref="signaturePad"
              width="100%"
              height="250px"
              :options="{ onBegin }"
            />
            <div class="row text-center">
              <div class="col-12">
                <hr />
                <b-button
                  size="sm"
                  variant="outline-secondary"
                  @click="esign_clear()"
                  >{{ $t('common.button.re_sign') }}</b-button
                >&nbsp;
                <b-button
                  size="sm"
                  variant="outline-success"
                  @click="esign_lock()"
                  >{{ $t('common.button.confirm_sign') }}</b-button
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-2"></div>
      </div>
      <br><br>
      <div class="row">
        <div class="col-12 text-center">
          <b-button variant="secondary" @click="reset()">{{
            $t('common.button.reset')
          }}</b-button
          >&nbsp;
          <b-button
            v-if="status == 1"
            variant="warning"
            @click="tempsave()"
            >{{ $t('common.button.tempsave') }}</b-button
          >&nbsp;
          <b-button variant="success" @click="validation_cus()">{{
            $t('common.button.submit')
          }}</b-button>
          <b-button
            id="confirmModal_open"
            variant="outline-success"
            style="display: none"
            data-bs-toggle="modal"
            data-bs-target="#confirmModal"
            >{{ $t('common.button.confirm') }}</b-button
          >
        </div>
      </div>
    </div>
    <div v-if="system_ready && all_complete">
      <PrintOutTemplate
        :form_master_id="template_id"
        :branch="branch"
      />
    </div>
  </b-overlay>
</template>

<script>
import PrintOutTemplate from '@/components/PrintOutTemplate'
export default {
  name: 'CRCTemplate',
  components: {
    PrintOutTemplate,
  },
  props: [
    'form_select',
    'form_master_id',
    'e_sign',
    'branch',
    'form_status'
  ],
  data() {
    return {
      template_id: 0,
      all_complete: false,
      checkvalid: false,
      system_ready: false,
      showoverlay: false,
      dismissCountDown: false,
      dismissCountDown2: false,
      lang: '',
      name_lang: '',
      info: [],
      ori_info: [],
      member_no: '',
      member_info: [],
      check_member_msg: '',
      form_date: new Date(),
      staff_code: '',
      staff_id: '',
      staff_code_checked: false,
      check_staff_code: true,
      advisor: '',
      advisor_list: '',
      check_advisor_msg: '',
      vaild_staff_code: false,
      member_name: '',
      nickname: '',
      gender: '',
      id_number: '',
      birthday: '',
      phone: '',
      email: '',
      address: '',
      height: '',
      weight: '',
      occupation: '',
      remark: '',
      ref: '',
      ref_name: '',
      ref_phone: '',
      ref_other: '',
      agreement_member: '',
      status: 1,
      options: [
        { item: 'Y', name_tc: '有', name_en: 'Yes' },
        { item: 'N', name_tc: '否', name_en: 'No' },
      ],
      options2: [
        { item: '1', name_tc: '失眠不能入睡', name_en: '失眠不能入睡' },
        { item: '2', name_tc: '睡醒次數頻密', name_en: '睡醒次數頻密' },
        { item: '3', name_tc: '睡眠時間不足', name_en: '睡眠時間不足' },
      ],
      options3: [
        { item: 'Y', name_tc: '有服藥', name_en: 'Yes' },
        { item: 'N', name_tc: '沒有服藥', name_en: 'No' },
      ],
      options4: [
        { item: '1', name_tc: '無需服藥', name_en: '無需服藥' },
        { item: '2', name_tc: '需服藥', name_en: '需服藥' },
        { item: '3', name_tc: '注射胰島素', name_en: '注射胰島素' },
      ],
      options5: [
        { item: '1', name_tc: '脂肪肝', name_en: '脂肪肝' },
        { item: '2', name_tc: '肝炎', name_en: '肝炎' },
        { item: '3', name_tc: '其他', name_en: '其他' },
      ],
      options6: [
        { item: '1', name_tc: '心臟起搏器', name_en: '心臟起搏器' },
        { item: '2', name_tc: '其他', name_en: '其他' },
      ],
      options7: [
        { item: '1', name_tc: '親友介紹', name_en: '親友介紹' },
        { item: '2', name_tc: '其他', name_en: '其他' },
      ],
      options8: [
        { item: 'M', name_tc: '男', name_en: 'M' },
        { item: 'F', name_tc: '女', name_en: 'F' },
      ],
    }
  },
  computed: {
  },
  watch: {
  },
  created() {
    var self = this
    self.lang = localStorage.getItem('locale')
    self.name_lang = 'name_' + self.lang
    self.template_id = self.form_master_id
    self.status = self.form_status
    self.get_api()
    if (self.template_id > 0) {
      self.get_form()
      if(self.status == 1){
        self.all_complete = true
      }
    }
  },
  mounted() {
    var self = this
    this.emitter.on('lang_changed', (lang) => {
      self.initialize()
      self.lang = lang
      self.name_lang = 'name_' + self.lang
    })
  },
  methods: {
    onBegin() {
      this.$refs.signaturePad.resizeCanvas()
    },
    esign_lock() {
      document.getElementById('e_sign_confirm').style.display = 'inline'
      this.$refs.signaturePad.lockSignaturePad()
    },
    esign_clear() {
      document.getElementById('e_sign_confirm').style.display = 'none'
      this.$refs.signaturePad.openSignaturePad()
      this.$refs.signaturePad.clearSignature()
    },
    initialize() {
      var self = this
      self.checkvalid = false
      self.member_name = ''
      self.nickname = ''
      self.gender = ''
      self.id_number = ''
      self.birthday = ''
      self.phone = ''
      self.email = ''
      self.address = ''
      self.height = ''
      self.weight = ''
      self.occupation = ''
      self.remark = ''
      self.ref = ''
      self.ref_name = ''
      self.ref_phone = ''
      self.ref_other = ''
      self.agreement_member = ''
      self.info = JSON.parse(JSON.stringify(self.ori_info))
    },
    get_api() {
      var self = this
      self.showoverlay = true
      self.system_ready = false
      this.axios
      .get('form/question/1', {})
      .then(function (response) {
        self.advisor_list = response.data.advisor_list
      })
      if(self.form_status == 1){
        this.axios
        .get('form/question/' + self.form_select, {})
        .then(function (response) {
          self.showoverlay = false
          self.info = response.data.data
          if (self.info.length > 0) {
            self.system_ready = true
            self.ori_info = JSON.parse(JSON.stringify(self.info))
          }
        })
      }
    },
    defaulttick(data){
      setTimeout(function () {
        if(data.ext_1 || data.ext_2 || data.ext_3 || data.ext_4 || data.ext_5 || data.ext_other){
          data.checkbox_value = "1"
        }
      }, 100)
    },
    get_form() {
      var self = this
      this.axios
        .get('form/detail/' + self.template_id, {})
        .then(function (response) {
          var info = response.data.data
          self.info = response.data.answer
          info.forEach(function (item) {
            if (item.field_name == 'member_no') {
              self.member_no = item.field_value
              return
            }
            if (item.field_name == 'advisor_id') {
              self.advisor = item.field_value
              return
            }
            if (item.field_name == 'member_name') {
              self.member_name = item.field_value
              return
            }
            if (item.field_name == 'nickname') {
              self.nickname = item.field_value
              return
            }
            if (item.field_name == 'gender') {
              self.gender = item.field_value
              return
            }
            if (item.field_name == 'id_number') {
              self.id_number = item.field_value
              return
            }
            if (item.field_name == 'phone') {
              self.phone = item.field_value
              return
            }
            if (item.field_name == 'email') {
              self.email = item.field_value
              return
            }
            if (item.field_name == 'birthday') {
              self.birthday = item.field_value
              return
            }
            if (item.field_name == 'address') {
              self.address = item.field_value
              return
            }
            if (item.field_name == 'height') {
              self.height = item.field_value
              return
            }
            if (item.field_name == 'weight') {
              self.weight = item.field_value
              return
            }
            if (item.field_name == 'occupation') {
              self.occupation = item.field_value
              return
            }
            if (item.field_name == 'remark') {
              self.remark = item.field_value
              return
            }
            if (item.field_name == 'agreement_member') {
              self.agreement_member = item.field_value
              return
            }
            if (item.field_name == 'ref') {
              self.ref = item.field_value
              return
            }
            if (item.field_name == 'ref_name') {
              self.ref_name = item.field_value
              return
            }
            if (item.field_name == 'ref_phone') {
              self.ref_phone = item.field_value
              return
            }
            if (item.field_name == 'ref_other') {
              self.ref_other = item.field_value
              return
            }
          })
          self.system_ready = true
          self.showoverlay = false
        })
    },
    getQuestion(id) {
      var self = this
      var list = {}
      list = self.info.filter(function (item) {
        return item.id == id
      })
      return list[0]
    },
    getOption(question, id) {
      var self = this
      var list = {}
      var option = {}
      list = self.info.filter(function (item) {
        return item.id == question
      })
      option = list[0].option.filter(function (item) {
        return item.id == id
      })
      return option[0]
    },
    member_check() {
      var self = this
      self.check_member_msg = ''
      if (self.member_no) {
        //POS API
          this.axios({
            method: 'post',
            url: 'https://record.ankh.com.hk/api/pos/v1/fetch/member',
            data: {
              find: self.member_no
            },
            headers: {
              'Authorization': `Bearer 68|003iXlulhk5hFHGUHVMyEenUbGxCXPAVDVNJIw38`,
              'X-Requested-With': `XMLHttpRequest`
            },
            withCredentials: true,
          }).then(function (response) {
            if(response.data.result == "success"){
              self.member_info = response.data.member
              self.member_no = self.member_info.memberId
              self.member_info.hideName = response.data.member.cardName
              self.member_info.hidePhone = response.data.member.phone[0]
                .toString()
                .replace(response.data.member.phone[0].toString().substring(0, 4), '****')
              console.log(self.member_info)
              document.getElementById('memberModal_open').click()
            }else{
              self.check_member_msg = '---找不到相關會員編號---'
            }
          })
      }
    },
    prefill(){
      var self = this
      self.initialize()
      if(self.member_info.cardName){
        self.member_name = self.member_info.cardName.trim()
      }
      if(self.member_info.gender){
        if(self.member_info.gender == 'f' || self.member_info.gender == 'F'){
          self.gender = 'F'
        }
        if(self.member_info.gender == 'm' || self.member_info.gender == 'M'){
          self.gender = 'M'
        }
      }
      if(self.member_info.phone.length > 0){
        self.phone = self.member_info.phone[0]
      }
      if(self.member_info.email){
        if (self.member_info.email.includes('@')) {
          self.email = self.member_info.email
        }
      }
      if(self.member_info.address.length > 0){
        self.member_info.address.forEach(function (item) {
          self.address = self.address + item
        })
      }
      document.getElementById('memberModal_close').click()
    },
    staff_code_check() {
      var self = this
      if (self.check_staff_code && self.staff_code) {
        this.axios
          .get('staff/vaild/' + self.staff_code, {})
          .then(function (response) {
            var staff_info = response.data.data
            if (staff_info.length > 0) {
              self.staff_id = staff_info[0]['id']
              self.vaild_staff_code = true
            } else {
              self.vaild_staff_code = false
            }
            self.staff_code_checked = true
          })
      }
    },
    reset() {
      var self = this
      window.scrollTo(0, 0)
      self.initialize()
      self.esign_clear()
      self.emitter.emit('reset')
    },
    validation_cus() {
      var self = this
      var validation_check = true
      const { isEmpty } = this.$refs.signaturePad.saveSignature()
      if (isEmpty == true) {
        document.getElementById('e_sign_label').style.display = 'inline'
        document.getElementById('e_sign_label').scrollIntoView()
        validation_check = false
      } else {
        document.getElementById('e_sign_confirm').style.display = 'none'
      }
      if(self.info){
        self.info.forEach(function (item) {
          if(!item.value && item.option.length == 0){
            validation_check = false
            self.checkvalid = true
            document.getElementById("question_section").scrollIntoView()
          }
        })
      }
      if (!self.advisor) {
        validation_check = false
        document.getElementById("advisor").scrollIntoView()
        setTimeout(function () {
          self.$refs.advisor.$el.focus()
          self.check_advisor_msg = '---健康顧問不能空白---'
        }, 300)
      }
      if (!self.member_no || self.check_member_msg) {
        validation_check = false
        document.getElementById("member_no").scrollIntoView()
        setTimeout(function () {
          self.$refs.member_no.$el.focus()
          self.check_member_msg = '---會員編號不能空白---'
        }, 300)
      }
      if (validation_check == true) {
        self.status = 1
        document.getElementById('confirmModal_open').click()
      }
    },
    tempsave() {
      var self = this
      self.status = 0
      self.submit()
    },
    submit() {
      var self = this
      self.showoverlay = true
      var birthday_format = ''
      if (self.birthday) {
        if (self.birthday.length == 10) {
          birthday_format = self.birthday
        } else {
          var offset = self.birthday.getTimezoneOffset()
          var date = new Date(self.birthday.getTime() - offset * 60 * 1000)
          birthday_format = date.toISOString().split('T')[0]
        }
      }
      var form_date_format = ''
      if (self.form_date.length == 10) {
        form_date_format = self.form_date
      } else {
        var fd_offset = self.form_date.getTimezoneOffset()
        var fd_date = new Date(self.form_date.getTime() - fd_offset * 60 * 1000)
        form_date_format = fd_date.toISOString().split('T')[0]
      }
      var dataset = {
        form_select: self.form_select,
        staff_id: self.staff_id,
        member_no: self.member_no,
        advisor_id: self.advisor,
        member_name: self.member_name,
        nickname: self.nickname,
        gender: self.gender,
        id_number: self.id_number,
        birthday: birthday_format,
        phone: self.phone,
        email: self.email,
        address: self.address,
        height: self.height,
        weight: self.weight,
        occupation: self.occupation,
        remark: self.remark,
        ref: self.ref,
        ref_name: self.ref_name,
        ref_phone: self.ref_phone,
        ref_other: self.ref_other,
        agreement_member: self.agreement_member,
        info: self.info,
      }
      document.getElementById('confirmModal_close').click()
      var data = {
        data: dataset,
        form_date: form_date_format,
        status: self.status,
        id: self.template_id,
        branch: self.branch,
      }
      this.axios
        .post('form/submit', {
          data: data,
        })
        .then(function (response) {
          self.pre_template_id = response.data.id
          setTimeout(function () {
            if(self.status == 1){
              self.upload()
            }else{
              window.scrollTo(0, 0)
              self.showoverlay = false
              self.dismissCountDown2 = true
              setTimeout(function () {
                self.reload()
              }, 3000)
            }
          }, 1000)
        })
    },
    upload() {
      var self = this
      const { data } = this.$refs.signaturePad.saveSignature()
      this.formData = new FormData()
      this.formData.append('file', data)
      this.formData.append('id', self.pre_template_id)
      this.axios
        .post('/form/upload', this.formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(function () {
          self.dismissCountDown = true
          window.scrollTo(0, 0)
          self.showoverlay = false
          setTimeout(function () {
            self.dismissCountDown = false
          }, 5000)
          self.template_id = self.pre_template_id
          self.all_complete = true
        })
    },
  },
}
</script>
