<template>
  <b-overlay :show="showoverlay" rounded="sm" class="text-left">
    <!--Print & Upload & Completed Function-->
    <div id="print_upload_bar" style="display: inline">
      <div class="d-print-none">
        <br />
        <div v-if="member_info" class="row">
          <div class="col-12 text-center">
            <h4>
              {{ member_info.cardName }}({{
                member_info.memberId
              }})
            </h4>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <span>1. {{ $t('common.button.print_sign') }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <b-button
              variant="outline-success"
              @click="loadPDF()"
              ><i class="fa fa-download fa-lg" aria-hidden="true"></i
            ></b-button>
          </div>
        </div>

        <div id="preview_block" style="display: none">
          <div class="row">
            <div class="col-12 text-center">
              <div
                class="imagePreviewWrapper"
                :style="{ 'background-image': `url(${previewImage})` }"
              ></div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <!--<b-button variant="outline-danger" @click="upload()"><i class="fa fa-upload fa-lg" aria-hidden="true"></i></b-button>-->
              <b-button variant="outline-danger" @click="upload()">{{
                $t('common.button.upload')
              }}</b-button>
            </div>
          </div>
        </div>
        <br />

        <div class="row">
          <div id="back_button" class="col-12 text-center" style="display: none">
            <b-button variant="outline-secondary" @click="reload()">{{
              $t('common.button.back')
            }}</b-button>
          </div>
          <div id="complete_button" class="col-12 text-center" style="display: inline">
            <b-button variant="success" @click="complete()">{{
              $t('common.button.complete')
            }}</b-button>
          </div>
        </div>
      </div>
    </div>
    <!-- Enf of Print & Upload -->
  </b-overlay>
</template>

<script>

export default {
  name: 'PrintOutTemplate',
  props: [
    'form_master_id',
    'branch',
  ],
  data() {
    return {
      close_tab: false,
      showoverlay: false,
      lang: '',
      name_lang: '',
      member_info: [],
    }
  },
  watch: {
  },
  created() {
    var self = this
    self.lang = localStorage.getItem('locale')
    self.name_lang = 'name_' + self.lang
    self.get_form_basic()
    if(this.$route.params.id) {
      self.close_tab = true
    }
  },
  mounted() {
    var self = this
    this.emitter.on('lang_changed', (lang) => {
      self.initialize()
      self.lang = lang
      self.name_lang = 'name_' + self.lang
    })
  },
  methods: {
    initialize() {
    },
    get_form_basic() {
      var self = this
      self.showoverlay = true
      this.axios
        .get('form/basic/' + self.branch + '/' + self.form_master_id, {})
        .then(function (response) {
          var info = response.data.data
          self.member_no = info[0].member_id
          setTimeout(function () {
            self.member_check()
          }, 300)
        })
    },
    member_check() {
      var self = this
      if (self.member_no) {
        //POS API
          this.axios({
            method: 'post',
            url: 'https://record.ankh.com.hk/api/pos/v1/fetch/member',
            data: {
              find: self.member_no
            },
            headers: {
              'Authorization': `Bearer 68|003iXlulhk5hFHGUHVMyEenUbGxCXPAVDVNJIw38`,
              'X-Requested-With': `XMLHttpRequest`
            },
            withCredentials: true,
          }).then(function (response) {
            if(response.data.result == "success"){
              self.member_info = response.data.member
              self.member_info.hideName = response.data.member.cardName
              self.member_info.hidePhone = response.data.member.phone[0]
                .toString()
                .replace(response.data.member.phone[0].toString().substring(0, 4), '****')
                self.showoverlay = false
            }
          })
      }
    },
  },
}
</script>
