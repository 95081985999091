<template>
  <!-- Modal for Auth -->
  <div
    id="authModal"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('common.message.auth') }}</h5>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <span>職員編號</span>
              <CFormInput
                v-model="staff_code"
                size="sm"
                type="password"
                @change="auth_check()"
              />
            </div>
          </div>
          <div v-if="check_staff_msg" class="row">
            <div class="col-12">
              <span class="text-danger">{{
                $t('common.message.staff_code_error')
              }}</span>
            </div>
          </div>
        </div>
        <div class="modal-footer" style="display: none">
          <button
            id="authModal_close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
      </div>
    </div>
  </div>
  <!-- End of Modal for Auth -->

  <!-- Delete Confirm Box -->
    <div>
      <b-modal class="text-secondary mb-0" size="sm" v-model="modalShow" hide-footer>
        <div>
          <div class="card-header pb-0">
            <div class="d-flex flex-row justify-content-between">
              <div v-if="modalShow">
                <h5 class="text-delete-warning">{{ $t("common.message.confirm_delete") }}</h5>
              </div>  
            </div>
          </div>
          <div class="d-flex flex-row justify-content-between">
              <div>                               
              </div>
              <div>
                <b-button @click="cancelDelete()" pill variant="outline-secondary">{{ $t("common.button.cancel") }}</b-button>
                &nbsp; <b-button @click="deleteForm()" pill variant="outline-danger">{{ $t("common.button.confirm") }}</b-button>
              </div>
          </div>
        </div>
      </b-modal>
    </div>
  <!-- End of Delete Confirm Box -->

  <!-- Function Bar(TOP) -->
  <div class="row d-print-none">
    <div class="col-3 text-left">
      <b-button style="background-color: white !important; border-radius: 2rem !important; color:rgb(4, 97, 66) !important;" href="/#/Dashboard" class="mx-1" variant="outline-light">
        <font-awesome-icon :icon="['fas', 'house-user']" size="2x"/>
      </b-button>
    </div>
    <div class="col-3 text-left">
      <b-button
        v-if="form_master_id == 0 && incomplete_action == false"
        variant="secondary"
        @click="incomplete_auth('incomplete')"
        >{{ $t('menu.form.incomplete') }}</b-button
      >
      <b-button
        v-if="incomplete_action == true"
        variant="secondary"
        @click="fill_form()"
        >{{ $t('menu.form.fill') }}</b-button
      >
      <b-button
        id="authModal_open"
        variant="outline-success"
        style="display: none"
        data-bs-toggle="modal"
        data-bs-target="#authModal"
        >{{ $t('common.button.confirm') }}</b-button
      >
    </div>
    <div class="col-3 text-left" v-if="form_select">
      <div v-if="Form_info[0].id == 3 && incomplete_action == false">
        <b-button
          v-if="form_master_id == 0"
          variant="secondary"
          @click="incomplete_auth('cm')"
        >
        {{ $t('common.button.edit') + Form_info[0].name_tc }}
        </b-button>
      </div>
    </div>
    <div
      v-if="form_master_id == 0 && incomplete_action == false"
      class="col-3 text-right"
    >
      <b-form-select v-model="form_select" class="mb-3 radius-half">
        <option v-for="item in info" :key="item.id" :value="item.form_id">
          {{ item.name_tc }}
        </option>
      </b-form-select>
    </div>
  </div>
  <!-- End of Function Bar -->

  <!-- Logo & Form name -->
  <div v-if="form_select">
    <div class="row">
      <div class="col-4 d-print-none"></div>
      <div class="col-4 text-left d-none d-print-block">
        <qrcode-vue :value="qr_code" />
      </div>
      <div class="col-4">
        <img :src="'/logo.svg'" />
      </div>
      <div class="col-4 text-right">
        <span v-if="incomplete_action == false">{{
          Form_info[0].form_code
        }}</span>
      </div>
    </div>
    <div class="row text-center">
      <div class="col-12">
        <div class="flex-row justify-content-between">
          <div>
            <h4 v-if="incomplete_action == false" class="text-bold mb-0">
              {{ Form_info[0].name_tc }} {{ Form_info[0].name_en }} ({{
                branch
              }})
            </h4>
            <h4 v-if="incomplete_action == true" class="text-bold mb-0">
              <span v-if="incomplete_type == 'incomplete'">{{ $t('menu.form.incomplete') }}</span>
              <span v-if="incomplete_type == 'cm'">{{ Form_info[0].name_tc }}{{ $t('common.message.list') }}</span>
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End of Logo & Form name -->

  <!-- Incomplete List -->
  <div id="incomplete_list" style="display: none">
    <div class="row text-left">
      <div class="col-12">
        <b-button variant="success" @click="getIncompleteList()">{{
          $t('common.button.update')
        }}</b-button>
      </div>
    </div>
    <div v-if="form_select">
      <div v-if="Form_info[0].id == 3" class="row text-left">
        <div class="col-12">
          <span style="color: red;">*如有需要, 請於1小時內修改已建立的記錄卡(逾時不可修改)</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table-lite
          :is-slot-mode="true"
          :columns="incomplete_fields"
          :rows="Active_incompleteList"
          :is-hide-paging="true"
        >
          <template #member="data">
            {{ data.value.member_name }} ({{ data.value.member_no }})
          </template>
          <template #form="data">
            {{ data.value.form_name_tc }}
          </template>
          <template #staff="data">
            {{ data.value.sta_nickname }} {{ data.value.sta_last_name }}
          </template>
          <template #editable="data">
            <span v-if="data.value.editable">{{$t('menu.form.incomplete_editable')}}</span>
            <span v-else>{{$t('menu.form.incomplete_uneditable')}}</span>
          </template>
          <template #status="data">
            <span v-if="data.value.status == 0">{{
              $t('menu.form.incomplete_form')
            }}</span>
            <span v-else>{{ $t('menu.form.incomplete_invoice') }}</span>
          </template>
          <template #action="data">
            <b-button
              variant="success"
              @click="continueForm(data.value.id)"
              >{{ $t('common.button.continue') }}</b-button
            >
            &nbsp;
            <b-button
              variant="danger"
              @click="confirmDelete(data.value.id)"
              >{{ $t('common.button.delete') }}</b-button
            >
          </template>
        </table-lite>
      </div>
    </div>
  </div>
  <!-- Enf of Incomplete List -->

  <!-- Form Info -->
  <div v-if="form_select" id="print_element" style="display: none">
    <b-overlay :show="showoverlay" rounded="sm">
      <div class="row">
        <div class="col-12">
          <div v-if="form_master_id > 0 && form_status == 1">
            <PrintOutTemplate
              :form_master_id="form_master_id"
              :branch="branch"
            />
          </div>
          <div v-else>
            <div v-if="Form_info[0].id == 1">
              <CRCTemplate
                :form_select="form_select"
                :form_master_id="form_master_id"
                :e_sign="e_sign"
                :branch="branch"
                :form_status="form_status"
              />
            </div>
            <div v-if="Form_info[0].id == 2">
              <PCTemplate
                :form_select="form_select"
                :form_master_id="form_master_id"
                :e_sign="e_sign"
                :branch="branch"
                :form_status="form_status"
              />
            </div>
            <div v-if="Form_info[0].id == 3">
              <CMTemplate
                :form_select="form_select"
                :form_master_id="form_master_id"
                :e_sign="e_sign"
                :editable="editable"
                :permission="permission"
              />
            </div>
            <div v-if="Form_info[0].id == 4">
              <NTTemplate
                :form_select="form_select"
                :form_master_id="form_master_id"
                :e_sign="e_sign"
              />
            </div>
            <div v-if="Form_info[0].id == 5">
              <PMTemplate
                :form_select="form_select"
                :form_master_id="form_master_id"
                :e_sign="e_sign"
              />
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
  <!-- End of Form Info -->
</template>

<script>
import CRCTemplate from '@/components/CRCTemplate'
import PCTemplate from '@/components/PCTemplate'
import CMTemplate from '@/components/CMTemplate'
import NTTemplate from '@/components/NTTemplate'
import PMTemplate from '@/components/PMTemplate'
import PrintOutTemplate from '@/components/PrintOutTemplate'

export default {
  name: 'FormApplication',
  components: {
    CRCTemplate,
    PCTemplate,
    CMTemplate,
    NTTemplate,
    PMTemplate,
    PrintOutTemplate,
  },
  data() {
    return {
      editable: false,
      e_sign: false,
      form_auth: false,
      form_master_id: 0,
      form_status: 1,
      showoverlay: false,
      qr_code: '',
      info: [],
      form_select: '',
      staff_code: '',
      check_staff_msg: '',
      staff_info: [],
      incomplete_action: false,
      incompleteList: [],
      incomplete_fields: [],
      form_delete_id: '',
      modalShow: false,
      incomplete_type: '',
      permission: [],
    }
  },
  computed: {
    Form_info: function () {
      var self = this
      return self.info.filter(function (item) {
        return item.form_id == self.form_select
      })
    },
    Active_incompleteList: function () {
      var self = this
      if(self.incomplete_type == "cm"){
        self.incompleteList.forEach(function (item) {
          var date = item.created_at
          var now = new Date()
          now.setHours(now.getHours() + 8)
          var diff = 60*60*1000
          if((now - new Date(date)) < diff) {
            item.editable = true
          }else{
            item.editable = false
          }
        })
        return self.incompleteList.filter(function (item) {
          return item.check_invoice == false
        })
      }else{
        return self.incompleteList
      }
    },
  },
  watch: {
    form_select() {
      var self = this
      //self.form_master_id = 0
      var select = self.info.filter(function (item) {
        return item.form_id == self.form_select
      })
      if (select[0].e_sign == 1) {
        self.e_sign = true
      } else {
        self.e_sign = false
      }
    },
  },
  created() {
    var self = this
    self.lang = localStorage.getItem('locale')
    self.name_lang = 'name_' + self.lang
    self.branch = this.$route.params.branch
    self.initialize()
    if (this.$route.params.id) {
      self.form_auth = true
      setTimeout(function () {
        document.getElementById('authModal_open').click()
      }, 300)
      self.form_master_id = this.$route.params.id
    } else {
      self.form_master_id = 0
      self.get_api()
      setTimeout(function () {
        document.getElementById('print_element').style.display = 'inline'
      }, 800)
    }
  },
  mounted() {
    var self = this
    this.emitter.on('lang_changed', (lang) => {
      self.initialize()
      self.lang = lang
      self.name_lang = 'name_' + self.lang
    })
  },
  methods: {
    initialize() {
      var self = this
      self.incomplete_fields = [
        {
          label: this.$t('menu.form.member'),
          field: 'member',
          sortable: false,
          isKey: false,
          width: "35%",
        },
        {
          label: this.$t('menu.form.form'),
          field: 'form',
          sortable: false,
          isKey: false,
          width: "15%",
        },
        {
          label: this.$t('menu.form.form_date'),
          field: 'form_date_format',
          sortable: false,
          isKey: false,
          width: "23%",
        },
        {
          label: this.$t('common.status'),
          field: 'status',
          sortable: false,
          isKey: false,
          width: "10%",
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          isKey: false,
          width: "18%",
        },
      ]
    },
    cm_field_list(){
      var self = this
      self.incomplete_fields = [
        {
          label: this.$t('menu.form.member'),
          field: 'member',
          sortable: false,
          isKey: false,
        },
        {
          label: this.$t('common.cm.name'),
          field: 'staff',
          sortable: false,
          isKey: false,
        },
        {
          label: this.$t('menu.form.form_date'),
          field: 'form_date',
          sortable: false,
          isKey: false,
        },
        {
          label: '',
          field: 'editable',
          sortable: false,
          isKey: false,
        },
        {
          label: this.$t('common.status'),
          field: 'status',
          sortable: false,
          isKey: false,
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          isKey: false,
        },
      ]
    },
    auth_check() {
      var self = this
      self.check_staff_msg = ''
      if (self.staff_code) {
        this.axios
          .get('staff/vaild/' + self.staff_code, {})
          .then(function (response) {
            self.staff_info = response.data.data
            self.permission = response.data.permission
            if (self.staff_info.length > 0) {
              self.staff_code = ''
              document.getElementById('authModal_close').click()
              if (self.form_auth == true) {
                self.form_auth = false
                self.get_api()
                self.qr_code = 'http://' + location.host + '/#' + self.$route.fullPath
                setTimeout(function () {
                  document.getElementById('print_element').style.display = 'inline'
                }, 300)
              } else {
                self.incomplete_action = true
                document.getElementById('print_element').style.display = 'none'
                document.getElementById('incomplete_list').style.display ='inline'
                self.getIncompleteList()
              }
            } else {
              self.check_staff_msg = '---找不到相關員工編號 / 沒有權限---'
            }
          })
      }
    },
    get_api() {
      var self = this
      this.axios.get('form/list/' + self.branch, {}).then(function (response) {
        self.showoverlay = false
        self.info = response.data.data
        self.form_select = self.info[0].form_id
        if (self.info[0].e_sign == 1) {
          self.e_sign = true
        }
        if(self.form_master_id > 0){
          self.get_form_basic()
        }
      })
    },
    get_form_basic() {
      var self = this
      this.axios
        .get('form/basic/' + self.branch + '/' + self.form_master_id, {})
        .then(function (response) {
          var info = response.data.data
          self.form_select = info[0].parent_id
          self.form_status = info[0].status
        })
    },
    getIncompleteList() {
      var self = this
      var staff = ''
      var found = self.permission.indexOf('invoice_edit')
      if(found >= 0){
        staff = 'all'
      }else{
        staff = self.staff_info[0]['id']
      }
      self.showoverlay = true
      this.formData = new FormData()
      this.formData.append('branch', self.branch)
      this.formData.append('type', self.incomplete_type)
      this.formData.append('form', self.form_select)
      this.formData.append('staff', staff)
      this.axios
      .post('/form/list/incomplete', this.formData, {
      })
      .then(function (response) {
        self.showoverlay = false
        self.incompleteList = response.data.data
        self.incompleteList.forEach(function (item) {
          if (item.member_name == '') {
            self.axios({
              method: 'post',
              url: 'https://record.ankh.com.hk/api/pos/v1/fetch/member',
              data: {
                find: item.member_no
              },
              headers: {
                'Authorization': `Bearer 68|003iXlulhk5hFHGUHVMyEenUbGxCXPAVDVNJIw38`,
                'X-Requested-With': `XMLHttpRequest`
              },
              withCredentials: true,
            }).then(function (response) {
              if(response.data.result == "success"){
                item.member_name = response.data.member.cardName
              }
            })
          }
        })
        if(self.incomplete_type == "incomplete"){
          self.initialize()
        }else{
          self.cm_field_list()
        }
      })
    },
    incomplete_auth(type) {
      var self = this
      self.incomplete_type = type
      document.getElementById('authModal_open').click()
    },
    fill_form() {
      var self = this
      self.incomplete_action = false
      document.getElementById('print_element').style.display = 'inline'
      document.getElementById('incomplete_list').style.display = 'none'
    },
    confirmDelete(id){
        var self = this
        self.form_delete_id = id
        self.modalShow = true
    },
    continueForm(id) {
      var self = this
      let route = this.$router.resolve({
        path: self.$route.fullPath + '/' + id,
      })
      window.open(route.href)
    },
    deleteForm() {
        var self = this
        this.formData = new FormData()
        this.formData.append('id', self.form_delete_id)
        this.formData.append('status', '-1')
        this.axios
        .post('/form/delete', this.formData, {
        })
        .then(function () {
            self.cancelDelete()
            self.getIncompleteList()
        })
    },
    cancelDelete() {
        var self = this
        self.modalShow = false
        self.form_delete_id = ''
    },
  },
}
</script>
