<template>
  <b-button
    id="memberModal_open"
    variant="outline-success"
    style="display: none"
    data-bs-toggle="modal"
    data-bs-target="#memberModal"
    >{{ $t('common.button.confirm') }}
  </b-button>
  <!-- Modal for confirmation -->
  <div
    id="memberModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="memberModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="memberModalLabel" class="modal-title"></h5>
          <button
            id="memberModal_close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="member_info" class="text-left">
            <div v-if="member_info.hideName" class="row">
              <div class="col-12">
                <span>姓名 - {{ member_info.hideName }}</span>
              </div>
            </div>
            <div v-if="member_info.hidePhone" class="row">
              <div class="col-12">
                <span>聯絡電話 - {{ member_info.hidePhone }}</span>
              </div>
            </div>
            <div v-if="member_info.gender" class="row">
              <div class="col-12">
                <span>性別 - {{ member_info.gender }}</span>
              </div>
            </div>
            <div v-if="member_info.email" class="row">
              <div class="col-12">
                <span>電郵 - {{ member_info.email }}</span>
              </div>
            </div>
            <div v-if="member_info.birthday" class="row">
              <div class="col-12">
                <span>出生日期 - {{ member_info.birthday }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-6 text-right">
                <b-button variant="success" @click="prefill()">{{
                  $t('common.button.apply')
                }}</b-button>
              </div>
              <div class="col-6 text-left">
                <b-button
                  variant="secondary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  >{{ $t('common.button.back') }}</b-button
                >
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" style="display: none">
          <b-button variant="outline-success" @click="submit()">{{
            $t('common.button.submit')
          }}</b-button>
        </div>
      </div>
    </div>
  </div>
  <!-- End of Modal for confirmation -->

  <!-- Modal for confirmation-->
  <div
    id="confirmModal"
    class="modal fade"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-labelledby="confirmModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="confirmModalLabel" class="modal-title"></h5>
          <button
            id="confirmModal_close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <span>職員編號</span>
              <CFormInput
                v-model="staff_code"
                size="sm"
                type="password"
                @change="staff_code_check()"
              />
            </div>
          </div>
          <div v-if="staff_code_checked && !vaild_staff_code" class="row">
            <div class="col-12">
              <span class="text-danger">{{
                $t('common.message.staff_code_error')
              }}</span>
            </div>
          </div>
          <div v-if="vaild_staff_code">
            <br /><b-button variant="success" @click="submit()">{{
              $t('common.button.submit')
            }}</b-button>
          </div>
        </div>
        <div class="modal-footer" style="display: none">
          <b-button variant="outline-success" @click="submit()">{{
            $t('common.button.submit')
          }}</b-button>
        </div>
      </div>
    </div>
  </div>

  <!-- Alert Message -->
  <div class="row d-print-none">
    <div class="col-12">
      <b-alert :show="dismissCountDown" dismissible fade variant="success">
        <span>{{ $t('common.message.successful') }}</span>
      </b-alert>
    </div>
    <div class="col-12">
      <b-alert :show="dismissCountDown2" dismissible fade variant="success">
        <span>{{ $t('common.message.tempsave_3stime') }}</span>
      </b-alert>
    </div>
  </div>
  <!-- End of Alert Message -->
  <b-overlay :show="showoverlay" rounded="sm" class="text-left">
    <!--Question-->
    <div v-if="system_ready && all_complete == false">
      <div class="row text-right">
        <div class="col-12">
          <span class="spc_small">(職員專用)</span>
        </div>
      </div>
      <div id="member_no" class="row">
        <div class="col-9 text-right">
          <span>會員編號 Membership No.</span>
        </div>
        <div class="col-3 text-left">
          <CFormInput
            ref="member_no"
            v-model="member_no"
            size="sm"
            type="text"
            @change="member_check()"
          />
        </div>
      </div>
      <div v-if="check_member_msg" class="row text-right">
        <div class="col-12">
          <span class="text-danger">{{ check_member_msg }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-9 text-right">
          <span>日期</span>
        </div>
        <div class="col-3 text-left">
          <datepicker
            v-model="form_date"
            size="sm"
            auto-apply
            input-format="yyyy-MM-dd"
            :clearable="false"
            :enable-time-picker="false"
          />
        </div>
      </div>
      <div id="advisor" class="row">
        <div class="col-9 text-right">
          <span>健康顧問 / Health Advisor</span>
        </div>
        <div class="col-3 text-left">
          <Multiselect
            id="advisor"
            ref="advisor"
            v-model="advisor"
            mode="single"
            :close-on-select="true"
            :searchable="false"
            :create-option="false"
            :options="advisor_list"
            value-prop="id"
            track-by="nickname"
            label="nickname"
          />
        </div>
      </div>
      <div v-if="check_advisor_msg" class="row text-right">
        <div class="col-12">
          <span class="text-danger">{{ check_advisor_msg }}</span>
        </div>
      </div>

      <div class="card mb-4">
        <div class="card-header pb-0">
          <!--Loop question from DB-->
          <div v-for="item in info" :key="item.id">
            <div class="row">
              <div class="col-6">
                {{ getQuestion(item.id).name_tc
                }}<span
                  v-if="
                    !item.value && item.option.length == 0 && checkvalid == true
                  "
                  class="text-danger"
                  >{{ $t('common.message.required') }}</span
                >
              </div>
              <div v-if="item.input_type == 'text'" class="col-6">
                <b-form-input v-model="item.value" size="sm"></b-form-input>
              </div>
              <div v-if="item.input_type == 'radio'" class="col-6">
                <b-form-radio-group
                  v-model="item.value"
                  :options="options"
                  value-field="item"
                  :text-field="name_lang"
                  disabled-field="notEnabled"
                ></b-form-radio-group>
              </div>
              <div v-if="item.input_type == 'oradio'" class="col-6">
                <b-form-radio-group
                  v-model="item.value"
                  :options="options2"
                  value-field="item"
                  :text-field="name_lang"
                  disabled-field="notEnabled"
                ></b-form-radio-group>
              </div>
              <div v-if="item.input_type == 'rating'" class="col-12">
                <div class="row">
                  <div class="col-2">最低</div>
                  <div class="col-8">
                    <b-form-radio-group
                      v-model="item.value"
                      :options="options3"
                      value-field="item"
                      :text-field="name_lang"
                      disabled-field="notEnabled"
                    ></b-form-radio-group>
                  </div>
                  <div class="col-2">最高</div>
                </div>
              </div>
              <div v-if="item.id == 9 && item.value == 'Y'" class="row">
                <div class="col-2">
                  <span>原因</span>
                </div>
                <div class="col-4">
                  <b-form-input
                    v-model="item.value_ext"
                    size="sm"
                  ></b-form-input>
                </div>
                <div class="col-2">
                  <span>受傷年份</span>
                </div>
                <div class="col-4">
                  <b-form-input
                    v-model="item.value_ext2"
                    size="sm"
                  ></b-form-input>
                </div>
              </div>
              <div v-if="item.id == 11 && item.value == 'Y'" class="row">
                <div class="col-2">
                  <span>什麼療程</span>
                </div>
                <div class="col-6">
                  <b-form-input
                    v-model="item.value_ext"
                    size="sm"
                  ></b-form-input>
                </div>
                <div class="col-2">
                  <span>年份</span>
                </div>
                <div class="col-2">
                  <b-form-input
                    v-model="item.value_ext2"
                    size="sm"
                  ></b-form-input>
                </div>
              </div>
              <div v-if="item.id == 12 && item.value == 'Y'" class="row">
                <div class="col-2">
                  <span>什麼療程</span>
                </div>
                <div class="col-10">
                  <b-form-input
                    v-model="item.value_ext"
                    size="sm"
                  ></b-form-input>
                </div>
              </div>
              <template v-if="item.option.length > 0">
                <div class="row">
                  <template v-for="option in item.option" :key="option.id">
                    <div :class="'col-' + option.column_width">
                      <div class="row">
                        <template v-if="option.cus_needed == 1">
                          <template v-if="option.id == 40">
                            <div class="col-4">
                              <b-form-checkbox
                                v-model="option.checkbox_value"
                                value="1"
                                unchecked-value="not_accepted"
                              >
                                {{ getOption(item.id, option.id).name_tc }}
                              </b-form-checkbox>
                            </div>
                            <div class="col-8">
                              <b-form-input
                                v-model="option.ext_1"
                                size="sm"
                                placeholder="位置"
                              ></b-form-input>
                            </div>
                          </template>
                          <template v-if="option.id == 46">
                            <div class="col-4">
                              <b-form-checkbox
                                v-model="option.checkbox_value"
                                value="1"
                                unchecked-value="not_accepted"
                              >
                                {{ getOption(item.id, option.id).name_tc }}
                              </b-form-checkbox>
                            </div>
                            <div class="col-8">
                              <b-form-input
                                v-model="option.ext_1"
                                size="sm"
                                placeholder=""
                              ></b-form-input>
                            </div>
                          </template>
                          <template v-if="option.id == 53">
                            <div class="col-7">
                              <b-form-checkbox
                                v-model="option.checkbox_value"
                                value="1"
                                unchecked-value="not_accepted"
                              >
                                {{ getOption(item.id, option.id).name_tc }}
                              </b-form-checkbox>
                            </div>
                            <div class="col-5">
                              <b-form-input
                                v-model="option.ext_1"
                                size="sm"
                                placeholder="備註"
                              ></b-form-input>
                            </div>
                          </template>
                          <template v-if="option.id == 54">
                            <div class="col-4">
                              <b-form-checkbox
                                v-model="option.checkbox_value"
                                value="1"
                                unchecked-value="not_accepted"
                              >
                                {{ getOption(item.id, option.id).name_tc }}
                              </b-form-checkbox>
                            </div>
                            <div class="col-8">
                              <b-form-input
                                v-model="option.ext_1"
                                size="sm"
                                placeholder=""
                              ></b-form-input>
                            </div>
                          </template>
                        </template>
                        <template v-else>
                          <div class="col-12">
                            <b-form-checkbox
                              v-model="option.checkbox_value"
                              value="1"
                              unchecked-value="not_accepted"
                            >
                              {{ getOption(item.id, option.id).name_tc }}
                            </b-form-checkbox>
                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                </div>
              </template>
            </div>
            <br />
          </div>
          <div class="row">
            <div class="col-1">備註</div>
            <div class="col-11">
              <b-form-input v-model="remark" size="sm"></b-form-input>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-12">
              <span class="spc_small"
                >*機能再生ANKH會因應您的需要，選擇適合的療程。</span
              >
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <span class="spc_small"
                >*本人明白接受有些療程可能會加促身體及皮膚的血液循環、製造運動效果、皮膚發熱、發紅、發瘀及起痧。</span
              >
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <span class="spc_small"
                >有可能在療程後數天內出現類似運動後的肌肉酸軟感。</span
              >
            </div>
          </div>
          <hr />
          <!--End of Loop-->
        </div>
      </div>
      <div class="row text-right">
        <div class="col-2"></div>
        <div class="col-8">
          <div class="text-center">
            <span id="e_sign_label" style="display: none; color: red"
              >顧客簽署</span
            ><br />
            <!--
          <b-button
            size="sm"
            variant="outline-secondary"
            @click="esign_clear()"
            >{{ $t('common.button.re_sign') }}</b-button
          >
          --></div>
          <div class="card mb-8">
            <span id="e_sign_confirm" style="display: none; color: red"
              >簽署已確定</span
            >
            <VueSignaturePad
              id="signature"
              ref="signaturePad"
              width="100%"
              height="250px"
              :options="{ onBegin }"
            />
            <div class="row text-center">
              <div class="col-12">
                <hr />
                <b-button
                  size="sm"
                  variant="outline-secondary"
                  @click="esign_clear()"
                  >{{ $t('common.button.re_sign') }}</b-button
                >&nbsp;
                <b-button
                  size="sm"
                  variant="outline-success"
                  @click="esign_lock()"
                  >{{ $t('common.button.confirm_sign') }}</b-button
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-2"></div>
      </div>
      <br /><br />
      <div class="row">
        <div class="col-12 text-center">
          <b-button variant="secondary" @click="reset()">{{
            $t('common.button.reset')
          }}</b-button
          >&nbsp;
          <b-button v-if="status == 1" variant="warning" @click="tempsave()">{{
            $t('common.button.tempsave')
          }}</b-button
          >&nbsp;
          <b-button variant="success" @click="validation_cus()">{{
            $t('common.button.submit')
          }}</b-button>
          <b-button
            id="confirmModal_open"
            variant="outline-success"
            style="display: none"
            data-bs-toggle="modal"
            data-bs-target="#confirmModal"
            >{{ $t('common.button.confirm') }}</b-button
          >
        </div>
      </div>
    </div>

    <div v-if="system_ready && all_complete">
      <PrintOutTemplate :form_master_id="template_id" :branch="branch" />
    </div>
  </b-overlay>
</template>

<script>
import PrintOutTemplate from '@/components/PrintOutTemplate'
export default {
  name: 'PCTemplate',
  components: {
    PrintOutTemplate,
  },

  props: ['form_select', 'form_master_id', 'e_sign', 'branch', 'form_status'],

  data() {
    return {
      template_id: 0,
      all_complete: false,
      lang: '',
      name_lang: '',
      checkvalid: false,
      showoverlay: false,
      system_ready: false,
      dismissCountDown: false,
      dismissCountDown2: false,
      info: [],
      ori_info: [],
      member_no: '',
      member_info: [],
      check_member_msg: '',
      vaild_staff_code: false,
      form_date: new Date(),
      staff_code: '',
      staff_id: '',
      staff_code_checked: false,
      check_staff_code: true,
      advisor: '',
      advisor_list: '',
      check_advisor_msg: '',

      nickname: '',
      status: 1,
      options: [
        { item: 'Y', name_tc: '有', name_en: 'Yes' },
        { item: 'N', name_tc: '否', name_en: 'No' },
      ],
      options2: [
        { item: 'Y', name_tc: '是', name_en: 'Yes' },
        { item: 'N', name_tc: '否', name_en: 'No' },
      ],
      options3: [
        { item: '1', name_tc: '1', name_en: '1' },
        { item: '2', name_tc: '2', name_en: '2' },
        { item: '3', name_tc: '3', name_en: '3' },
        { item: '4', name_tc: '4', name_en: '4' },
        { item: '5', name_tc: '5', name_en: '5' },
        { item: '6', name_tc: '6', name_en: '6' },
        { item: '7', name_tc: '7', name_en: '7' },
        { item: '8', name_tc: '8', name_en: '8' },
        { item: '9', name_tc: '9', name_en: '9' },
        { item: '10', name_tc: '10', name_en: '10' },
      ],
    }
  },
  watch: {},
  created() {
    var self = this
    self.lang = localStorage.getItem('locale')
    self.name_lang = 'name_' + self.lang
    self.template_id = self.form_master_id
    self.status = self.form_status
    self.get_api()
    if (self.template_id > 0) {
      self.get_form()
      if(self.status == 1){
        self.all_complete = true
      }
    }
    console.log(self.template_id)
    console.log(self.status)
    console.log(self.all_complete)
    console.log(self.system_ready)
  },

  mounted() {
    var self = this
    this.emitter.on('lang_changed', (lang) => {
      self.initialize()
      self.lang = lang
      self.name_lang = 'name_' + self.lang
    })
  },

  methods: {
    onBegin() {
      this.$refs.signaturePad.resizeCanvas()
    },
    esign_lock() {
      document.getElementById('e_sign_confirm').style.display = 'inline'
      this.$refs.signaturePad.lockSignaturePad()
    },
    esign_clear() {
      document.getElementById('e_sign_confirm').style.display = 'none'
      this.$refs.signaturePad.openSignaturePad()
      this.$refs.signaturePad.clearSignature()
    },

    initialize() {
      var self = this
      self.remark = ''
      self.info = JSON.parse(JSON.stringify(self.ori_info))
    },

    get_api() {
      // var self = this
      // self.showoverlay = true
      // self.system_ready = false
      // this.axios.get('form/question/2', {}).then(function (response) {
      //   self.advisor_list = response.data.advisor_list
      //     self.info = response.data.data
      //     self.showoverlay = false
      //     if (self.info.length > 0) {
      //       self.ori_info = JSON.parse(JSON.stringify(self.info))
      //     }
      //   })
      var self = this
      self.showoverlay = true
      self.system_ready = false
      this.axios.get('form/question/2', {}).then(function (response) {
        self.advisor_list = response.data.advisor_list
      })
      if (self.form_status == 1) {
        this.axios
          .get('form/question/' + self.form_select, {})
          .then(function (response) {
            self.showoverlay = false
            self.info = response.data.data
            if (self.info.length > 0) {
              self.system_ready = true
              self.ori_info = JSON.parse(JSON.stringify(self.info))
            }
          })
      }
    },
    get_form() {
      var self = this
      this.axios
        .get('form/detail/' + self.form_master_id, {})
        .then(function (response) {
          var info = response.data.data
          self.info = response.data.answer
          info.forEach(function (item) {
            if (item.field_name == 'member_no') {
              self.member_no = item.field_value
              return
            }
            if (item.field_name == 'advisor_id') {
              self.advisor = item.field_value
              return
            }
            if (item.field_name == 'remark') {
              self.remark = item.field_value
              return
            }
          })
        })
      self.system_ready = true
      self.showoverlay = false
    },

    getQuestion(id) {
      var self = this
      var list = {}
      list = self.info.filter(function (item) {
        return item.id == id
      })
      return list[0]
    },
    getOption(question, id) {
      var self = this
      var list = {}
      var option = {}
      list = self.info.filter(function (item) {
        return item.id == question
      })
      option = list[0].option.filter(function (item) {
        return item.id == id
      })
      return option[0]
    },

    member_check() {
      var self = this
      self.check_member_msg = ''
      if (self.member_no) {
        //POS API
        this.axios({
          method: 'post',
          url: 'https://record.ankh.com.hk/api/pos/v1/fetch/member',
          data: {
            find: self.member_no,
          },
          headers: {
            Authorization: `Bearer 68|003iXlulhk5hFHGUHVMyEenUbGxCXPAVDVNJIw38`,
            'X-Requested-With': `XMLHttpRequest`,
          },
          withCredentials: true,
        }).then(function (response) {
          if (response.data.result == 'success') {
            self.member_info = response.data.member
            self.member_no = self.member_info.memberId
            self.member_info.hideName = response.data.member.cardName
            self.member_info.hidePhone = response.data.member.phone[0]
              .toString()
              .replace(
                response.data.member.phone[0].toString().substring(0, 4),
                '****',
              )
            console.log(self.member_info)
            document.getElementById('memberModal_open').click()
          } else {
            self.check_member_msg = '---找不到相關會員編號---'
          }
        })
      }
    },
    
    staff_code_check() {
      var self = this
      if (self.check_staff_code && self.staff_code) {
        this.axios
          .get('staff/vaild/' + self.staff_code, {})
          .then(function (response) {
            var staff_info = response.data.data
            if (staff_info.length > 0) {
              self.staff_id = staff_info[0]['id']
              self.vaild_staff_code = true
            } else {
              self.vaild_staff_code = false
            }
            self.staff_code_checked = true
          })
      }
    },
    prefill(){
      var self = this
      self.initialize()
      document.getElementById('memberModal_close').click()
    },


    reset() {
      var self = this
      window.scrollTo(0, 0)
      // self.initialize()
      self.esign_clear()
      self.emitter.emit('reset')
    },

    validation_cus() {
      var self = this
      var validation_check = true
      const { isEmpty } = this.$refs.signaturePad.saveSignature()
      if (isEmpty == true) {
        document.getElementById('e_sign_label').style.display = 'inline'
        document.getElementById('e_sign_label').scrollIntoView()
        validation_check = false
      } else {
        document.getElementById('e_sign_confirm').style.display = 'none'
      }
      if (self.info) {
        self.info.forEach(function (item) {
          if (!item.value && item.option.length == 0) {
            validation_check = false
            self.checkvalid = true
            document.getElementById('question_section').scrollIntoView()
          }
        })
      }
      if (!self.advisor) {
        validation_check = false
        document.getElementById('advisor').scrollIntoView()
        setTimeout(function () {
          self.$refs.advisor.$el.focus()
          self.check_advisor_msg = '---健康顧問不能空白---'
        }, 300)
      }
      if (!self.member_no || self.check_member_msg) {
        validation_check = false
        document.getElementById('member_no').scrollIntoView()
        setTimeout(function () {
          self.$refs.member_no.$el.focus()
          self.check_member_msg = '---會員編號不能空白---'
        }, 300)
      }
      if (validation_check == true) {
        self.status = 1
        document.getElementById('confirmModal_open').click()
      }
    },

    tempsave() {
      var self = this
      self.status = 0
      self.submit()
    },
    submit() {
      var self = this
      self.showoverlay = true
      var form_date_format = ''
      if (self.form_date.length == 10) {
        form_date_format = self.form_date
      } else {
        var fd_offset = self.form_date.getTimezoneOffset()
        var fd_date = new Date(self.form_date.getTime() - fd_offset * 60 * 1000)
        form_date_format = fd_date.toISOString().split('T')[0]
      }
      var dataset = {
        form_select: self.form_select,
        member_no: self.member_no,
        staff_id: self.staff_id,
        remark: self.remark,
        info: self.info,
        advisor_id: self.advisor,
        member_name: self.member_name,
      }
      document.getElementById('confirmModal_close').click()
      var data = {
        data: dataset,
        form_date: form_date_format,
        status: self.status,
        id: self.template_id,
        branch: self.branch,
      }
      this.axios
        .post('form/submit', {
          data: data,
        })
        .then(function (response) {
          self.pre_template_id = response.data.id
          setTimeout(function () {
            if (self.status == 1) {
              self.upload()
            } else {
              window.scrollTo(0, 0)
              self.showoverlay = false
              self.dismissCountDown2 = true
              setTimeout(function () {
                self.reload()
              }, 3000)
            }
          }, 1000)
        })
    },
    upload() {
      var self = this
      const { data } = this.$refs.signaturePad.saveSignature()
      this.formData = new FormData()
      this.formData.append('file', data)
      this.formData.append('id', self.pre_template_id)
      this.axios
        .post('/form/upload', this.formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(function () {
          self.dismissCountDown = true
          window.scrollTo(0, 0)
          self.showoverlay = false
          setTimeout(function () {
            self.dismissCountDown = false
          }, 5000)
          self.template_id = self.pre_template_id
          self.all_complete = true
        })
    },
  },
}
</script>
