<template>
  <!-- Modal for confirmation-->
  <div
    id="confirmModal"
    class="modal fade"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-labelledby="confirmModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="confirmModalLabel" class="modal-title"></h5>
          <button
            id="confirmModal_close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <span>職員編號</span>
              <CFormInput
                v-model="staff_code"
                size="sm"
                type="password"
                @change="staff_code_check()"
              />
            </div>
          </div>
          <div v-if="staff_code_checked && !vaild_staff_code" class="row">
            <div class="col-12">
              <span class="text-danger">{{
                $t('common.message.staff_code_error')
              }}</span>
            </div>
          </div>
          <div v-if="vaild_staff_code">
            <br /><b-button variant="outline-success" @click="submit()">{{
              $t('common.button.submit')
            }}</b-button>
          </div>
        </div>
        <div class="modal-footer" style="display: none">
          <b-button variant="outline-success" @click="submit()">{{
            $t('common.button.submit')
          }}</b-button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal for Re-Draw -->
  <div
    id="redrawModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="redrawModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
  >
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="redrawModalLabel" class="modal-title"></h5>
        </div>
        <div class="modal-body">
          <span style="color: red;">確定要重新繪畫該中醫圖片?</span>
        </div>
        <div class="modal-footer">
          <b-button id="redrawModal_close" variant="outline-secondary" size="sm" data-bs-dismiss="modal" aria-label="Close">{{
            $t('common.button.cancel')
          }}</b-button>
          <b-button variant="outline-danger" size="sm" @click="background_clear()">{{
            $t('common.button.reset')
          }}</b-button>
        </div>
      </div>
    </div>
  </div>
  <!-- End of Modal for Re-Draw -->

  <b-overlay :show="showoverlay" rounded="sm" class="text-left unselectable">
    <!--Question-->
    <div v-if="system_ready">
      <!--Member Info-->
      <!--Section 1-->
      <div class="row">
        <div class="col-4">
          <span>會員編號</span>
          <CFormInput
            id="member_no"
            ref="member_no"
            v-model="member_no"
            size="sm"
            type="text"
            v-if="edit && form_master_id == 0"
            @change="member_check()"
          />
          <span v-else><br>{{ member_no }}</span>
          <div v-if="check_member_msg" class="row text-left">
            <div class="col-12">
              <span class="text-danger">{{ check_member_msg }}</span>
            </div>
          </div>
        </div>
        <div class="col-4">
          <span>會員姓名</span>
          <span><br>{{ member_name }}</span>
        </div>
        <div class="col-4">
          <span>發票編號</span>
          <b-form-input v-if="check_permission('invoice_edit')" v-model="invoice_no" size="sm"></b-form-input>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <span>中醫師姓名</span>
          <Multiselect
            v-model="cm_name"
            mode="single"
            :close-on-select="true"
            :searchable="true"
            :create-option="false"
            :options="cm_list"
            valueProp="id"
            trackBy="show_name"
            label="show_name"
            :disabled="mode == 'edit'"
          />
        </div>
        <div class="col-4">
          <span>註冊編號</span>
          <span><br>{{ reg_no }}</span>
        </div>
        <div class="col-4">
          <span>診症日期</span>
          <datepicker
            v-model="dia_date"
            size="sm"
            auto-apply
            input-format="yyyy-MM-dd"
            :clearable="false"
            :enable-time-picker="false"
            v-if="edit"
          />
          <span v-else><br>{{ dia_date }}</span>
        </div>
      </div>
      <br>

      <div class="row">
        <div class="col-12 text-center">
          <div id="cm_draw_lock" style="display: inline;">
            <span style="display: none; color: red;" id="cm_imageDrawing_confirm">已確定</span>
            <br>
            <img v-if="cm_image" :src="cm_image" :width="720" :height="480"/>
            <img v-else :src="backgroundImage" :width="720" :height="480"/>
          </div>
          <div id="cm_draw_edit" style="display: none; user-select: none; -webkit-user-select: none; -moz-user-select: none;">
            <vue-drawing-canvas
              ref="cm_imageDrawing"
              v-model:image="cm_image"
              :width="720"
              :height="480"
              stroke-type="dash"
              :color="color"
              :lineWidth="line"
              :background-image="backgroundImage"
              saveAs="png"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <div id="cm_draw_button" style="display: none;">
            <b-button
              size="sm"
              variant="outline-secondary"
              @click="background_undo()"
              >{{ $t('common.button.undo') }}</b-button
            >&nbsp;
            <!--
            <b-button
              size="sm"
              variant="outline-secondary"
              @click="background_redo()"
              >{{ $t('common.button.redo') }}</b-button
            >&nbsp;
            -->
            <b-button
              id="cm_draw_button_reset"
              style="display: inline;"
              size="sm"
              variant="outline-danger"
              data-bs-toggle="modal"
              data-bs-target="#redrawModal"
              >{{ $t('common.button.reset') }}</b-button
            >&nbsp;
            <b-button
              id="cm_draw_button_confirm"
              style="display: inline;"
              size="sm"
              variant="outline-success"
              @click="cm_imageDrawLock()"
              >{{ $t('common.button.confirm') }}</b-button
            >
            <b-button
              id="cm_draw_button_edit"
              style="display: none;"
              size="sm"
              variant="outline-secondary"
              @click="cm_imageDraw()"
              >{{ $t('common.button.draw_edit') }}</b-button
            >&nbsp;
            <b-button
              size="sm"
              variant="outline-secondary"
              @click="cm_imageDrawColor()"
              >{{ $t('common.button.draw_color') }}</b-button
            >&nbsp;
            <span id="cm_imagePenColor" style="background-color:#000000">&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </div>
          <div id="cm_draw_start">
            <b-button
              size="sm"
              variant="outline-secondary"
              @click="cm_imageDraw()"
              v-if="edit"
              >{{ $t('common.button.draw') }}</b-button
            >
          </div>
        </div>
      </div>
      <!--Loop question from DB-->
      <div v-for="item in info" :key="item.id">
        <div class="row">
          <div class="col-2">
            {{ getQuestion(item.id).name_tc }}
          </div>
          <div v-if="item.input_type == 'text'" class="col-10">
            <b-form-input v-if="edit" v-model="item.value" size="sm"></b-form-input>
            <span v-else>{{ item.value }}</span>
          </div>
          <div v-if="item.input_type == 'textarea'" class="col-10">
            <b-form-textarea
              v-model="item.value"
              size="sm"
              v-if="edit"
            ></b-form-textarea>
            <span v-else>{{ item.value }}</span>
          </div>
        </div>
      </div>
      <br>
      <!--End of Loop-->
      <!--End of Section 1-->
      <div v-if="e_sign == true && edit && (form_master_id == 0 || temp_status)" class="row text-right">
        <div class="col-2"></div>
        <div class="col-8">
          <div class="text-center">
            <span id="e_sign_label">客人簽署</span><br />
            <!--
            <b-button
              size="sm"
              variant="outline-secondary"
              @click="esign_clear('signature')"
              >{{ $t('common.button.re_sign') }}</b-button
            >&nbsp;
            <b-button
              size="sm"
              variant="outline-success"
              @click="esign_lock('e_sign_confirm')"
              >{{ $t('common.button.confirm') }}</b-button
            >
            -->
          </div>
          <div class="card mb-8">
            <span style="display: none; color: red;" id="e_sign_confirm">客人簽署已確定</span>
            <VueSignaturePad
              id="signature"
              ref="signaturePad"
              width="100%"
              height="250px"
              :options="{ onBegin }"
            />
            <div class="row text-center">
              <div class="col-12">
                <hr />
                <b-button
                  size="sm"
                  variant="outline-secondary"
                  @click="esign_clear('signature')"
                  >{{ $t('common.button.re_sign') }}</b-button
                >&nbsp;
                <b-button
                  size="sm"
                  variant="outline-success"
                  @click="esign_lock('e_sign_confirm')"
                  >{{ $t('common.button.confirm_sign') }}</b-button
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-2"></div>
      </div>
      <br>
      <div v-if="e_sign == true && edit && (form_master_id == 0 || temp_status)" class="row text-right">
        <div class="col-2"></div>
        <div class="col-8">
          <div class="text-center">
            <span id="cm_e_sign_label">中醫師簽署</span><br />
            <!--
            <b-button
              size="sm"
              variant="outline-secondary"
              @click="esign_clear('cm_signature')"
              >{{ $t('common.button.re_sign') }}</b-button
            >&nbsp;
            <b-button
              size="sm"
              variant="outline-success"
              @click="esign_lock('cm_e_sign_confirm')"
              >{{ $t('common.button.confirm') }}</b-button
            >
            -->
          </div>
          <div class="card mb-8">
            <span style="display: none; color: red;" id="cm_e_sign_confirm">中醫師簽署已確定</span>
            <VueSignaturePad
              id="cm_signature"
              ref="cm_signaturePad"
              width="100%"
              height="250px"
              :options="{ onBegin }"
            />
            <div class="row text-center">
              <div class="col-12">
                <hr />
                <b-button
                  size="sm"
                  variant="outline-secondary"
                  @click="esign_clear('cm_signature')"
                  >{{ $t('common.button.re_sign') }}</b-button
                >&nbsp;
                <b-button
                  size="sm"
                  variant="outline-success"
                  @click="esign_lock('cm_e_sign_confirm')"
                  >{{ $t('common.button.confirm_sign') }}</b-button
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-2"></div>
      </div>
      <br><br>
      <div class="row d-print-none">
        <div class="col-12 text-center">
          <b-button v-if="form_master_id == 0" variant="secondary" @click="reset()">{{
            $t('common.button.reset')
          }}</b-button
          >&nbsp;
          <b-button
            v-if="!temp_status && form_master_id == 0"
            variant="warning"
            @click="tempsave()"
            >{{ $t('common.button.tempsave') }}</b-button
          >&nbsp;
          <b-button variant="success" @click="validation_cus()">{{
            $t('common.button.submit')
          }}</b-button>
          <b-button
            id="confirmModal_open"
            variant="outline-success"
            style="display: none"
            data-bs-toggle="modal"
            data-bs-target="#confirmModal"
            >{{ $t('common.button.confirm') }}</b-button
          >
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import VueDrawingCanvas from "vue-drawing-canvas"
export default {
  name: 'PCTemplate',
  components: {
    VueDrawingCanvas,
  },
  props: [
    'form_select',
    'form_master_id',
    'temp_status',
    'e_sign',
    'editable',
    'permission',
  ],
  data() {
    return {
      edit: true,
      cm_image: "",
      line: 3,
      backgroundImage: null,
      ori_backgroundImage: null,
      color: "#000000",
      system_ready: false,
      showoverlay: false,
      lang: '',
      name_lang: '',
      info: [],
      ori_info: [],
      staff_code: '',
      staff_id: '',
      staff_code_checked: false,
      check_staff_code: true,
      vaild_staff_code: false,
      member_no: '',
      check_member_msg: '',
      member_name: '',
      invoice_no: '',
      cm_name: '',
      cm_list: [],
      reg_no: '',
      dia_date: new Date(),
      status: 1,
    }
  },
  watch: {
    form_select() {
      var self = this
      self.get_api()
    },
    cm_name() {
      var self = this
      var list = self.cm_list.filter(function (item) {
        return item.id == self.cm_name
      })
      self.reg_no = list[0].registration_number
    },
  },
  created() {
    var self = this
    self.lang = localStorage.getItem('locale')
    self.name_lang = 'name_' + self.lang
    setTimeout(function () {
      self.get_api()
    }, 300)
    setTimeout(function () {
      if (self.form_master_id > 0) {
        self.get_form()
        self.edit = self.editable
      }
    }, 800)
  },
  mounted() {
    var self = this
    this.emitter.on('lang_changed', (lang) => {
      self.initialize()
      self.lang = lang
      self.name_lang = 'name_' + self.lang
    })
  },
  methods: {
    background_undo() {
      var self = this
      self.cm_imageDrawUnLock()
      this.$refs.cm_imageDrawing.undo()
    },
    background_redo() {
      var self = this
      self.cm_imageDrawUnLock()
      this.$refs.cm_imageDrawing.redo()
    },
    background_clear() {
      var self = this
      self.cm_imageDrawUnLock()
      if(self.form_master_id > 0){
        self.backgroundImage = self.ori_backgroundImage
        self.$refs.cm_imageDrawing.redraw()
      }
      this.$refs.cm_imageDrawing.reset()
      document.getElementById('redrawModal_close').click()
    },
    cm_imageDraw() {
      var self = this
      self.cm_imageDrawUnLock()
      document.getElementById('cm_draw_button').style.display = 'inline'
      document.getElementById('cm_draw_lock').style.display = 'none'
      document.getElementById('cm_draw_edit').style.display = 'inline'
      document.getElementById('cm_draw_start').style.display = 'none'
      document.getElementById('cm_draw_button_reset').style.display = 'inline'
      document.getElementById('cm_draw_button_confirm').style.display = 'inline'
      document.getElementById('cm_draw_button_edit').style.display = 'none'
    },
    cm_imageDrawLock() {
      document.getElementById('cm_draw_lock').style.display = 'inline'
      document.getElementById('cm_draw_edit').style.display = 'none'
      document.getElementById('cm_draw_button_reset').style.display = 'none'
      document.getElementById('cm_draw_button_confirm').style.display = 'none'
      document.getElementById('cm_draw_button_edit').style.display = 'inline'
      document.getElementById('cm_imageDrawing_confirm').style.display = 'inline'
    },
    cm_imageDrawColor() {
      var self = this
      var old_color = self.color
      if(old_color == "#000000"){
        self.color = "#237823"
      }else if(old_color == "#237823"){
        self.color = "#FF0000"
      }else if(old_color == "#FF0000"){
        self.color = "#000000"
      }
      document.getElementById('cm_imagePenColor').style.backgroundColor = self.color
    },
    cm_imageDrawUnLock() {
      document.getElementById('cm_imageDrawing_confirm').style.display = 'none'
    },
    onBegin() {
      this.$refs.signaturePad.resizeCanvas()
      this.$refs.cm_signaturePad.resizeCanvas()
    },
    esign_clear(type) {
      if(type == 'signature'){
        document.getElementById('e_sign_confirm').style.display = 'none'
        this.$refs.signaturePad.openSignaturePad()
        this.$refs.signaturePad.clearSignature()
      }else if(type == 'cm_signature'){
        document.getElementById('cm_e_sign_confirm').style.display = 'none'
        this.$refs.cm_signaturePad.openSignaturePad()
        this.$refs.cm_signaturePad.clearSignature()
      }else{
        this.$refs.signaturePad.clearSignature()
        this.$refs.cm_signaturePad.clearSignature()
      }
    },
    esign_lock(type) {
      if(type == 'e_sign_confirm'){
        document.getElementById('e_sign_confirm').style.display = 'inline'
        this.$refs.signaturePad.lockSignaturePad()
      }else if(type == 'cm_e_sign_confirm'){
        document.getElementById('cm_e_sign_confirm').style.display = 'inline'
        this.$refs.cm_signaturePad.lockSignaturePad()
      }
    },
    initialize() {
      var self = this
      self.member_no = ''
      self.member_name = ''
      self.invoice_no = ''
      self.cm_name = ''
      self.reg_no = ''
      self.dia_date = new Date()
      self.status = 1
      self.info = JSON.parse(JSON.stringify(self.ori_info))
    },
    get_api() {
      var self = this
      self.showoverlay = true
      self.system_ready = false
      this.axios
        .get('form/question/' + self.form_select, {})
        .then(function (response) {
          self.showoverlay = false
          self.info = response.data.data
          if (self.info.length > 0) {
            self.system_ready = true
            self.ori_info = JSON.parse(JSON.stringify(self.info))
            self.backgroundImage = "data:image/png;base64,"+response.data.image
          }
      })
      this.formData = new FormData()
      this.formData.append('job_title', 3)
      this.axios
        .post('/staff/master', this.formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(function (response) {
          self.cm_list = response.data.data
        })
    },
    member_check() {
      var self = this
      self.check_member_msg = ''
      self.member_name = ''
      if (self.member_no) {
        //POS API
          this.axios({
            method: 'post',
            url: 'https://record.ankh.com.hk/api/pos/v1/fetch/member',
            data: {
              find: self.member_no
            },
            headers: {
              'Authorization': `Bearer 68|003iXlulhk5hFHGUHVMyEenUbGxCXPAVDVNJIw38`,
              'X-Requested-With': `XMLHttpRequest`
            },
            withCredentials: true,
          }).then(function (response) {
            if(response.data.result == "success"){
              var member_info = response.data.member
              self.member_no = member_info.memberId
              if(member_info.englishName){
                self.member_name = member_info.englishName
              }else if(member_info.surname){
                self.member_name = member_info.surname
              }else if(member_info.cardName){
                self.member_name = member_info.cardName
              }
            }else{
              self.check_member_msg = '---找不到相關會員編號---'
            }
          })
      }
    },
    check_permission(permission) {
      var self = this
      var found = self.permission.indexOf(permission)
      if(found >= 0){
        return true
      }else{
        return false
      }
    },
    get_form() {
      var self = this
      this.axios
        .get('form/detail/' + self.form_master_id, {})
        .then(function (response) {
          var info = response.data.data
          self.info = response.data.answer
          info.forEach(function (item) {
            if (item.field_name == 'member_no') {
              self.member_no = item.field_value
              return
            }
            if (item.field_name == 'member_name') {
              self.member_name = item.field_value
              return
            }
            if (item.field_name == 'invoice_no') {
              self.invoice_no = item.field_value
              return
            }
            if (item.field_name == 'cm_name') {
              self.cm_name = item.field_value
              return
            }
            if (item.field_name == 'reg_no') {
              self.reg_no = item.field_value
              return
            }
            if (item.field_name == 'dia_date') {
              self.dia_date = item.field_value
              return
            }
          })
          self.backgroundImage = "data:image/jpeg;base64,"+response.data.image
          self.ori_backgroundImage = "data:image/jpeg;base64,"+response.data.ori_image
          self.$refs.cm_imageDrawing.redraw()
        })
    },
    getQuestion(id) {
      var self = this
      var list = {}
      list = self.info.filter(function (item) {
        return item.id == id
      })
      return list[0]
    },
    getOption(question, id) {
      var self = this
      var list = {}
      var option = {}
      list = self.info.filter(function (item) {
        return item.id == question
      })
      option = list[0].option.filter(function (item) {
        return item.id == id
      })
      return option[0]
    },
    staff_code_check() {
      var self = this
      if (self.check_staff_code && self.staff_code) {
        this.axios
          .get('staff/vaild/' + self.staff_code, {})
          .then(function (response) {
            var staff_info = response.data.data
            if (staff_info.length > 0) {
              self.staff_id = staff_info[0]['id']
              self.vaild_staff_code = true
            } else {
              self.vaild_staff_code = false
            }
            self.staff_code_checked = true
          })
      }
    },
    reset() {
      var self = this
      window.scrollTo(0, 0)
      self.initialize()
      self.background_clear()
      self.esign_clear('all')
      self.emitter.emit('reset')
    },
    validation_cus() {
      var validation_check = true
      if(self.form_master_id == 0){
        const { isEmpty } = this.$refs.signaturePad.saveSignature()
        var cm = this.$refs.cm_signaturePad.saveSignature()
        if (cm.isEmpty == true) {
          document.getElementById('cm_e_sign_label').style.color = 'red'
          document.getElementById("cm_e_sign_label").scrollIntoView()
          validation_check = false
        } else {
          document.getElementById('cm_e_sign_label').style.color = 'black'
        }
        if (isEmpty == true) {
          document.getElementById('e_sign_label').style.color = 'red'
          document.getElementById("e_sign_label").scrollIntoView()
          validation_check = false
        } else {
          document.getElementById('e_sign_label').style.color = 'black'
        }
      }
      if (validation_check == true) {
        document.getElementById('confirmModal_open').click()
      }
    },
    tempsave() {
      var self = this
      self.status = 0
      self.submit()
    },
    submit() {
      var self = this
      var sign_image = {data: ""}
      var cm_image = {data: ""}
      if(self.form_master_id == 0 || self.temp_status){
        sign_image = this.$refs.signaturePad.saveSignature()
        cm_image = this.$refs.cm_signaturePad.saveSignature()
      }
      var dia_date_format = ''
      if (self.dia_date.length == 10) {
        dia_date_format = self.dia_date
      } else {
        var fd_offset = self.dia_date.getTimezoneOffset()
        var date = new Date(self.dia_date.getTime() - fd_offset * 60 * 1000)
        dia_date_format = date.toISOString().split('T')[0]
      }
      var dataset = {
        form_select: self.form_select,
        staff_id: self.staff_id,
        member_no: self.member_no,
        member_name: self.member_name,
        invoice_no: self.invoice_no,
        cm_name: self.cm_name,
        reg_no: self.reg_no,
        dia_date: dia_date_format,
        cm_image: self.cm_image,
        cm_signature: cm_image.data,
        info: self.info,
      }
      document.getElementById('confirmModal_close').click()
      self.emitter.emit('form_submit', {
        dataset: dataset,
        status: self.status,
        e_sign: sign_image.data,
      })
      if (self.status == 0) {
        self.initialize()
        self.esign_clear()
      }
    },
  },
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style>
  .unselectable {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
</style>